<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Program</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					<li class="breadcrumb-item">
                        <router-link to="/mitra"
                            >List Program Donasi</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Program Donasi
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Lihat Program Donasi</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Judul </label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="name"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Singkat</label
                                        >
                                        <div class="col-sm-10">
                                          <p v-html="short_content"></p>
                                        </div>
                                    </div>
									  <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Lengkap</label
                                        >
                                        <div class="col-sm-10">
                                          <p v-html="full_content"></p>
                                        </div>
                                    </div>
									
									 <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Target Donasi </label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="target"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
									
									
									 <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Foto</label
                                        >
                                        <div class="col-sm-10">
                                             <img style='width:150px;height:150px;' v-bind:src="imageURL + '/' + filename"/>
                                        </div>
                                    </div>
									
									 <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Tipe Donasi </label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="tipe"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail" class="col-sm-2 col-form-label">Featured</label>
                                        <div class="col-sm-10">
                                            <select readonly v-model="featured" class="form-control">
                                                <option value="0">Tidak</option>
                                                <option value="1">Ya</option>
                                    
                                            </select>
                                        </div>
                                    </div>

									
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Status</label
                                        >
                                        <div class="col-sm-10">
											<select readonly  v-model="status" class="form-control">
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</select>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Urutan</label
                                        >
                                        <div class="col-sm-10">
                                            <input readonly
												min="1"
												 v-model="urutan"
                                                type="number"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Urutan"
                                            />
                                        </div>
                                    </div>
                                    
                                   
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
