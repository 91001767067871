import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/faq";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL,{
            headers: {
                'Special': '1',
            }, // Pass the headers object here
          }
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const find = async (id:string) => {
	try {
		const response = await axios.get(
          baseURL + "/" + id
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const create = async (question:string, answer:string) => {
	try {
		const response = await axios.post(
          baseURL,
		  {
			"question":question,
			"answer":answer,
			"sort":1,
			"status":"Active",
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const update = async (id:string, question:string, answer:string) => {
	try {
		const response = await axios.put(
          baseURL + "/" +  id ,
		  {
			"question":question,
			"answer":answer,

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const remove = async (id:number) => {
	try {
		const response = await axios.delete(
          baseURL + "/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}