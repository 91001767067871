<pf-dropdown hide-arrow class="user-menu">
    <div slot="button">
        <pf-image
            :src="user && user.picture"
            :fallbackSrc="require('@/assets/img/default-profile.png')"
            class="user-image-small"
            width="25"
            height="25"
            alt="User Image"
            rounded
        ></pf-image>
    </div>
    <div slot="menu">
        <li class="user-header bg-primary">
            <pf-image
                :src="user && user.picture"
                :fallbackSrc="require('@/assets/img/default-profile.png')"
                class="user-image-big"
                alt="User Image"
                width="90"
                height="90"
                rounded
            ></pf-image>

            <p>
                <span>{{user && user.email}}</span>
               
            </p>
        </li>
        <!-- Menu Body -->
      
        <!-- Menu Footer-->
        <li class="user-footer">
           
            <button
                @click="logout"
                class="btn btn-default btn-flat float-right"
            >
                {{ $t("labels.signOut") }}
            </button>
        </li>
    </div>
</pf-dropdown>
