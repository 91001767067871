<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Slider</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					<li class="breadcrumb-item">
                        <router-link to="/slider"
                            >List Slider</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Slider
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Edit Slider</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Judul</label
                                        >
                                        <div class="col-sm-10">
                                            <input
											 v-model="name"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
									
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Slider (Max 100 chars)</label
                                        >
                                        <div class="col-sm-10">
                                           <textarea maxlength="100" class="form-control" v-model="short_content"></textarea>
                                        </div>
                                    </div>
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Foto</label
                                        >
                                        <div class="col-sm-10">
                                            <input
												
                                                type="file"
												v-on:change="uploadFile"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Jawaban"
                                            />
                                        </div>
                                    </div>
									
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Status</label
                                        >
                                        <div class="col-sm-10">
											<select  v-model="status" class="form-control">
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</select>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Urutan</label
                                        >
                                        <div class="col-sm-10">
                                            <input
												min="1"
												 v-model="urutan"
                                                type="number"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Urutan"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
                                                type="button"
												@click="submit"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
