<footer class="main-footer">
    <strong>
        <span>{{ $t("labels.copyright") }} &copy; {{currentYear}}</span>
        <a href="#" target="_blank" rel="noopener noreferrer">
            <span>&nbsp;pmi.or.id</span>
        </a>
        <span>.</span>
    </strong>
    <div class="float-right d-none d-sm-inline-block">
        <b>{{ $t("labels.version") }}</b>
        <span>:&nbsp;{{version}}</span>
    </div>
</footer>
