import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiEmail+ "/mail";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const BlastEmail = async (title:string, full_content:string) => {
    try {
        const response = await axios.post(
            baseURL,
            {
                "title":title,
                "full_content":full_content,
            }
        );

        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
