import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/content";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const getContent = async () => {
	try {
		const response = await axios.get(
          baseURL + "/1",
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const updateContent = async (about_us:string,  vision:string, mission:string, structure_organization:string, filename:string) => {
	try {
		const response = await axios.put(
            baseURL + "/1",
		  {
			"vision":vision,
			"mission":mission,
			"about_us":about_us,
			"structure_organization":structure_organization,
			"filename":filename,

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}