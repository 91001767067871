import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/program';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-program',
    components: {
        QuillEditor,
    },
	data() {
      return {
        title: "List Program",
		mode:1
      };
    },
})
export default class Program extends Vue {
	public name: string = '';
	public tipe:string = '';
	public status: string = '1';
	public urutan: number = 1;
	public short_content: string = '';
	public full_content: string = '';
	public logo: string = '';
	public target: number = 0;
	public featured:number = 0;
	private toast = useToast();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}
	
	public async submit(): Promise<void>{
        try {
		
			if(this.name == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.short_content == "")
			{
				this.toast.error('Isi Singkat harus diisi');
				return;
			}
			
			if(this.short_content == "")
			{
				this.toast.error('Isi Lengkap harus diisi');
				return;
			}
			
			
			if(this.tipe == "")
			{
				this.toast.error('Tipe Donasi harus diisi');
				return;
			}
			
			if(this.logo == "")
			{
				this.toast.error('Foto harus diisi');
				return;
			}
           
            const response = await create(this.name, this.logo, this.full_content, this.short_content, this.urutan,  this.status, this.target, this.tipe, this.featured);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Program Donasi berhasil disimpan');
				this.name = "";
				this.logo = "";
				this.short_content = "";
				this.full_content = "";
				this.$router.push("/program");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
