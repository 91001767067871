import { Options, Vue } from 'vue-class-component';
import { getTotalprogram,getTotaldonasi,getDonatur,getListdonasi } from "@/services/dashboard";
import { useToast } from "vue-toastification";
import ChartComponent from './ChartComponent.vue';
import PieComponent from './PieComponent.vue';
import ProgramBulan from "./ProgramBulan.vue";
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';

@Options({
    name: 'app-dashboard',
    components: {
        ChartComponent,
        PieComponent,
        ProgramBulan,
    },
    data() {
        return {
            dB: [],
            tP: '',
            tD: '',
            donatur: '',
            mode: 1,
        };
    },
    async mounted() {
        await this.loadData();
    },

})
export default class Dashboard extends Vue {
    public tP = '';
    public tD = '';
    public dB = '';
    public donatur = '';
    records: any = [];
    private toast = useToast();

    public mounted(): void {
        this.loadDashboard();
        console.log('Data Records:', this.records);

    }
    public async loadDashboard() : Promise<void>{
        try {
            const result = await getTotalprogram();
            if (result.status === false) {
                this.toast.error(result.message);
            } else {
                this.tP = result.data[0].total;
            }

            const tDr = await getTotaldonasi();
            if(tDr.status == false)
            {
                this.toast.error(tDr.message);
            }else {
                //this.tD = parseInt(tDr.data[0].amount);
                const totalDonationValue = parseInt(tDr.data[0]?.amount) || 0;
                this.tD = totalDonationValue.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
                console.log('this.tD after updating:', this.tD);
            }
            const dtR = await getDonatur();
            if(dtR.status == false)
            {
                this.toast.error(dtR.message);
            }else {
                this.donatur = dtR.data[0].total;
            }
            const lD = await getListdonasi();
            if (lD.status == false) {
                this.toast.error(lD.message);
            } else {
                this.records = lD.data;
                console.log('Data after setting records:', this.records);
                this.$nextTick(() => {
                    $('table').DataTable({
                        dom: 'lBfrtip',
                        lengthMenu: [5, 10, 25, 50, 100],
                        pageLength: 5,
                    });
                });
            }

        } catch (error: any) {
            this.toast.error(error.message);

        }

    }
    formatCurrency(value) {
        return parseFloat(value).toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        });
    }
}



