import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/toc';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-toc',
    components: {
        QuillEditor,
    },
	data() {
      return {
        title: "List Term of Condition",
		mode:1
      };
    },
})
export default class Toc extends Vue {
	
	public status: string = '1';
	public urutan: number = 1;
	public content: string = '';
	private toast = useToast();
	
	public async submit(): Promise<void>{
        try {
			if(this.content == "")
			{
				this.toast.error('Isi Syarat dan Ketentuan harus diisi');
				return;
			}
           
            const response = await create(this.content, this.urutan,  this.status);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Syarat dan Ketentuan berhasil disimpan');
				this.$router.push("/toc");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
