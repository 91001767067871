import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/channel';


@Options({
    name: 'app-channel',
    components: {
        
    },
	data() {
      return {
        title: "List Payment Channel",
		mode:1
      };
    },
})
export default class Channel extends Vue {
	public name: string = '';
	public kategori: string = '1';
	public logo:any;
	private toast = useToast();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}

	
	public async submit(): Promise<void>{
        try {
		
			if(this.name == "")
			{
				this.toast.error('Nama Payment Channel harus diisi');
				return;
			}
			
			if(this.logo == "")
			{
				this.toast.error('Logo harus diisi');
				return;
			}

			if(this.kategori == "")
			{
				this.toast.error('Kategori harus diisi');
				return;
			}
           
            const response = await create(this.name, this.logo, this.kategori);
			if(response.kategori == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Payment Channel berhasil disimpan');
				this.name = "";
				this.logo = "";
				this.$router.push("/channel");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
