import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {BlastEmail} from "@/services/blastEmail";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-BlastEmail',
    components: {
        QuillEditor,
    },
})
export default class blastEmail extends Vue {
    public name: string = '';
    public full_content: string = '';
    private toast = useToast();

    public async submit(): Promise<void>{
        try {

            if(this.name == "")
            {
                this.toast.error('Judul email harus di isi');
                return;
            }

            if(this.full_content == "")
            {
                this.toast.error('Isi Lengkap harus diisi');
                return;
            }

            const response = await BlastEmail(this.name, this.full_content,);
            if(response.status == false)
            {
                this.toast.error(response.message);
            }
            else
            {
                this.toast.success('Program Donasi berhasil disimpan');
                this.name = "";
                this.full_content = "";
                this.$router.push("/blast_email");
            }

        } catch (error: any) {
            this.toast.error(error.message);

        }
    }
}
