<aside v-if="menu" class="main-sidebar elevation-4" :class="sidebarSkin">
    <router-link to="/" class="brand-link">
        <pf-image
            :src="require('@/assets/img/pmi.png')"
            alt="PMI Logo"
            class="brand-image"
            height="33"
            width="33"
            rounded
            style="opacity: 0.8"
        ></pf-image>
        <span class="brand-text font-weight-light">PMI - CMS</span>
    </router-link>

    <div class="sidebar">
        <!--<div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <pf-image
                    :src="user && user.picture"
                    :fallbackSrc="require('@/assets/img/default-profile.png')"
                    class="img-circle"
                    alt="User Image"
                    width="34"
                    height="34"
                    rounded
                ></pf-image>
            </div>
            <div class="info">
                <router-link to="/profile" class="d-block">
                    {{user && user.email}}
                </router-link>
            </div>
        </div>-->

        <div class="form-inline">
            <app-sidebar-search></app-sidebar-search>
        </div>

        <nav class="mt-2" style="overflow-y: hidden">
            <ul
                class="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
            >
                <app-menu-item
                    v-for="item in menu"
                    :menuItem="item"
                ></app-menu-item>
            </ul>
        </nav>
    </div>
</aside>
