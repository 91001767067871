<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Program Donasi</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Program Donasi
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Tambah Program Donasi</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Judul Program </label
                                        >
                                        <div class="col-sm-10">
                                            <input
											 v-model="name"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                   <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Singkat (Max 100 chars)</label
                                        >
                                        <div class="col-sm-10">
										<textarea maxlength="100" class="form-control" v-model="short_content"></textarea>
										
                                           
                                        </div>
                                    </div>
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Lengkap</label
                                        >
                                        <div class="col-sm-10">
                                           <QuillEditor toolbar="full" theme="snow" v-model:content="full_content" contentType="html"/>
                                           
                                        </div>
                                    </div>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Target Donasi (Rp)</label
                                        >
                                        <div class="col-sm-10">
                                            <input
												v-model="target"
                                                type="number"
												min="0"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Target"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="inputEmail" class="col-sm-2 col-form-label">Foto</label>
                                        <div class="col-sm-10">
                                            <input type="file" v-on:change="uploadFile" class="form-control" id="inputEmail" placeholder="Jawaban" />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail" class="col-sm-2 col-form-label">Tipe Program</label>
                                        <div class="col-sm-10">
                                            <select v-model="tipe" class="form-control">
                                                <option value="Donasi Umum">Donasi Umum</option>
                                                <option value="Donasi Program">Donasi Program</option>
                                                <option value="Donasi Khusus">Donasi Khusus</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail" class="col-sm-2 col-form-label">Featured</label>
                                        <div class="col-sm-10">
                                            <select v-model="featured" class="form-control">
                                                <option value="0">Tidak</option>
                                                <option value="1">Ya</option>
                                    
                                            </select>
                                        </div>
                                    </div>

									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Status</label
                                        >
                                        <div class="col-sm-10">
											<select  v-model="status" class="form-control">
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</select>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Urutan</label
                                        >
                                        <div class="col-sm-10">
                                            <input
												min="1"
												 v-model="urutan"
                                                type="number"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Urutan"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
                                                type="button"
												@click="submit"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
