import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {changePassword} from '@/services/user';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-password',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "Ganti Password",
		mode:1
      };
    },
})
export default class User extends Vue {
	public confirm_password: string = '';
	public password: string = '';

	private toast = useToast();
	
	public async submit(): Promise<void>{
        try {
		
			
			if(this.password == "")
			{
				this.toast.error('Password harus diisi');
				return;
			}
			
			if(this.confirm_password == "")
			{
				this.toast.error('Konfirmasi Password harus diisi');
				return;
			}
			
			if(this.password != this.confirm_password)
			{
				this.toast.error('Password dan Konfirmasi harus sama');
				return;
			}
			
		
            changePassword(this.$store.state.auth.token, this.password);
			this.toast.success('Password berhasil disimpan');
			this.$router.push("/");
			
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
