<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Kontak PMI</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Kontak PMI
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Edit Kontak PMI</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Alamat </label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="text"
												v-model="address"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Address"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Email </label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="email"
												v-model="email"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputName2"
                                            class="col-sm-2 col-form-label"
                                            >Telp </label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="text"
												v-model="phone"
                                                class="form-control"
                                                id="inputName2"
                                                placeholder="Phone"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputFacebook"
                                            class="col-sm-2 col-form-label"
                                            >Facebook</label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="url"
                                                class="form-control"
                                                id="inputFacebook"
												v-model="facebook"
                                                placeholder="Facebook"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputSkills"
                                            class="col-sm-2 col-form-label"
                                            >Youtube</label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="url"
                                                class="form-control"
                                                id="inputSkills"
												v-model="youtube"
                                                placeholder="Youtube"
                                            />
                                        </div>

                                    </div>
                                     <div class="form-group row">
                                        <label
                                            for="inputSkills"
                                            class="col-sm-2 col-form-label"
                                            >Twitter</label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="url"
                                                class="form-control"
                                                id="inputSkills"
												v-model="twitter"
                                                placeholder="Twitter"
                                            />
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputSkills"
                                            class="col-sm-2 col-form-label"
                                            >Instagram</label
                                        >
                                        <div class="col-sm-10">
                                            <input
                                                type="url"
                                                class="form-control"
                                                id="inputSkills"
												v-model="instagram"
                                                placeholder="Instagram"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
                                                type="button"
												@click="submit"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
