import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {update, find} from '@/services/faq';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useRoute } from 'vue-router';


@Options({
    name: 'app-faq',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "List FAQ",
		mode:1
      };
    },
})
export default class Faq extends Vue {
	public question: string = '';
	public answer: string = '';
	private toast = useToast();
	private route = useRoute();
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('FAQ tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				
				this.question = response.data.question;
				this.answer = response.data.answer;

			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	public async submit(): Promise<void>{
	
		const id = this.route.params.id + "";
		
		try {
			if(this.question == "")
			{
				this.toast.error('Pertanyaan harus diisi');
				return;
			}
			
			if(this.answer == "")
			{
				this.toast.error('Jawaban harus diisi');
				return;
			}
			
			
            const response = await update(id, this.question, this.answer);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('FAQ berhasil diubah');
				this.question = "";
				this.answer = "";
				this.$router.push("/faq");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
