<template>
  <div class="container">
    <Bar v-if="loaded" :data="chartData" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { getBulanprogram } from "@/services/dashboard";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  data: () => ({
    loaded: false,
    chartData: null
  }),
  async mounted () {
    this.loaded = false

    try {
      const response = await getBulanprogram()
      if (response.data && response.data.length > 0) {
        this.chartData = {
          labels: response.data.map(item => `${item.program}`),
          datasets: [
            {
              label: 'Total Donation Amount',
              data: response.data.map(item => item.total),
              backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }
            // Mungkin tambahkan dataset lain jika dibutuhkan
          ]
        };
      } else {
        console.error('Data from the API is undefined or empty.');
      }
      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
}
</script>