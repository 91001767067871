import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import {load, loadProgram, filter} from '@/services/donation';
import {useToast} from 'vue-toastification';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';

@Options({
    name: 'app-donation',
    components: {
        
    },
	data() {
      return {
        title: "List Donasi",
		mode:1
      };
    },
})

export default class Donation extends Vue {
	private toast = useToast();
	records: any = [];
    from: "";
    to:"";
    tP:"";
    tipe:"";
    program:"";
    recordsProgram: any = [];


	public mounted(): void {
        this.loadDataProgram();
        this.loadData();
        setTimeout(function() {
			$('table').DataTable();
		}, 200);
    }

    public async loadDataProgram() : Promise<void>{
        try {
          
            const response = await loadProgram();
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.recordsProgram = response.data;
				
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
    public async submit() : Promise<void>{
        try {

            const response = await filter(this.from,this.to,this.program,this.tipe);
            if(response.status == false)
            {
                this.toast.error(response.message);
            }
            else
            {
                this.records = response.data;


            }

        } catch (error: any) {
            this.toast.error(error.message);

        }
    }
	
	public async loadData() : Promise<void>{
        try {
          
            const response = await load();
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.records = response.data;
				
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
