import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/channel";


const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const find = async (id) => {
	try {
		const response = await axios.get(
          baseURL + "/" + id
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const create = async (name:string, logo:any, kategori:string) => {
	try {
		
		const response = await axios.post(
          baseURL,
		  {
			"name":name,
			"logo":logo,
			"kategori":kategori,
			
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const update = async (id, name:string, logo:any, kategori:string, filename:string ) => {
	try {
		const response = await axios.put(
          baseURL + "/" + id,
		  {
			"name":name,
			"kategori":kategori,
			
			"logo":logo,
			"filename":filename,
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const remove = async (id) => {
	try {
		const response = await axios.delete(
          baseURL + "/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}