import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {update, find} from '@/services/publication';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill'

@Options({
    name: 'app-publication',
    components: {
        QuillEditor,
    },
	data() {
      return {
        title: "List Publication",
		mode:1
      };
    },
})
export default class Publication extends Vue {
	public name: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public short_content: string = '';
	public full_content: string = '';
	public author: string = '';
	public youtube: string = '';
	public date: string = '';
	public logo: string = '';
	public headline: string = '';
	public filename: string = '';
	private toast = useToast();
	private route = useRoute();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}
	
	mounted()
	{
	  this.load();
	};
	
	private pad(pad, str, padLeft) {
	  if (typeof str === 'undefined') 
		return pad;
	  if (padLeft) {
		return (pad + str).slice(-pad.length);
	  } else {
		return (str + pad).substring(0, pad.length);
	  }
	}
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Publikasi tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.filename = response.data.photo;
				this.name = response.data.title;
				this.urutan = response.data.sort;
				this.status = response.data.status;
				this.short_content = response.data.short_content;
				this.full_content = response.data.full_content;
				this.headline = response.data.headline;
				this.author = response.data.author;
				this.youtube = response.data.youtube;
				const date = new Date(response.data.publication_date.split("T")[0]);
				
				this.date = date.getFullYear() + "-" + this.pad('00', date.getMonth(), true) + "-" + this.pad('00', date.getDate(), true);
				
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	
	public async submit(): Promise<void>{
        try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Publikasi tidak valid');
				this.$router.push("/");
				return;
			}
			
			if(this.name == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.author == "")
			{
				this.toast.error('Author harus diisi');
				return;
			}
			
			
			if(this.date == "")
			{
				this.toast.error('Tanggal Publikasi harus diisi');
				return;
			}
			
			if(this.short_content == "")
			{
				this.toast.error('Isi Singkat harus diisi');
				return;
			}
			
			if(this.full_content == "")
			{
				this.toast.error('Isi Lengkap harus diisi');
				return;
			}
           
            const response = await update(id, this.name, this.logo, this.author, this.date, this.youtube, this.full_content, this.short_content, this.urutan,  this.status, this.headline, this.filename);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Publikasi berhasil diubah');
				this.$router.push("/publication");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
