import axios from "axios";
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/dashboard";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const getTotalprogram= async () => {
    try {
        const response = await axios.get(
            baseURL + "/totalprogram",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const getTotaldonasi= async () => {
    try {
        const response = await axios.get(
            baseURL + "/totaldonasi",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const getDonatur= async () => {
    try {
        const response = await axios.get(
            baseURL + "/donatur",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const getDonasibulan= async () => {
    try {
        const response = await axios.get(
            baseURL + "/donasi",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const getListdonasi= async () => {
    try {
        const response = await axios.get(
            baseURL + "/listdonasi",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }
}
export const getDonasiprogram= async () => {
    try {
        const response = await axios.get(
            baseURL + "/donasiprogram",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const getBulanprogram= async () => {
    try {
        const response = await axios.get(
            baseURL + "/programbulan",
        );
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}