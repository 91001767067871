import {Options, Vue} from 'vue-class-component';
import {getContent, updateContent} from '@/services/content';
import {useToast} from 'vue-toastification';
import { QuillEditor } from '@vueup/vue-quill'
import {environment} from '../../../environments/environment';


@Options({
    name: 'app-content',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "List Content",
		mode:1,
		imageURL:environment.imageURL
      };
    },
})
export default class Content extends Vue {
	public about_us: string = '';
	public mission: string = '';
	public vision: string = '';
	public filename:string = '';
	public structure_organization: string = '';
	
	private toast = useToast();

	public mounted(): void {
        this.loadContent();
    }

	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.structure_organization = reader.result.toString().split(',')[1];
        };
		
	}	
	
	public async submit(): Promise<void>{
        try {
			if(this.about_us == "")
			{
				this.toast.error('Tentang PMI harus diisi');
				return;
			}
			if(this.mission == "")
			{
				this.toast.error('Misi harus diisi');
				return;
			}
			if(this.vision == "")
			{
				this.toast.error('Visi harus diisi');
				return;
			}
		
           
            const contents = await updateContent(this.about_us, this.vision, this.mission, this.structure_organization, this.filename);
			if(contents.status == false)
			{
				this.toast.error(contents.message);
			}
			else
			{
				this.toast.success('Info PMI berhasil diubah');
				this.loadContent();
				this.$router.push("/content");
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
	
	public async loadContent() : Promise<void>{
        try {
           
            const contents = await getContent();
			if(contents.status == false)
			{
				this.toast.error(contents.message);
			}
			else
			{
				this.about_us = contents.data.about_us;
				this.mission = contents.data.mission;
				this.vision = contents.data.vision;
				this.filename = contents.data.structure_organization;

			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
