

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>FAQ</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					<li class="breadcrumb-item">
                        <router-link to="/faq"
                            >List FAQ</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        FAQ
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Tambah FAQ</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <ckeditor :editor="editor" v-model="question" :config="editorConfig"></ckeditor>
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Pertanyaan </label
                                        >
                                        <div class="col-sm-10">
                                         
											<QuillEditor toolbar="full" theme="snow" 
										
											v-model:content="question" contentType="html"/>
										
                                        </div>
                                    </div>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Jawaban  </label
                                        >
                                        <div class="col-sm-10">
										
                                            <QuillEditor toolbar="full" theme="snow" 
											
											v-model:content="answer" contentType="html"/>
                                        </div>
                                    </div>
                                    <p>&nbsp;</p>
									<p>&nbsp;</p>
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
											@click="submit"
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
