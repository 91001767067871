import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/service";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL,{
            headers: {
                'Special': '1',
            }, // Pass the headers object here
          }
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const find = async (id:string) => {
	try {
		const response = await axios.get(
          baseURL + "/" + id
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const create = async (name:string, photo:string, full_content:string, short_content:string, urutan:number, status:string) => {
	try {
		const response = await axios.post(
          baseURL,
		  {
			"title":name,
			"photo":photo,
			"short_content":short_content,
			"full_content":full_content,
			"sort":urutan,
			"status":status,
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const update = async (id:string, name:string, photo:string, full_content:string, short_content:string, urutan:number, status:string, filename:string) => {
	try {
		const response = await axios.put(
          baseURL + "/" + id,
		  {
			
			"title":name,
			"photo":photo,
			"short_content":short_content,
			"full_content":full_content,
			"sort":urutan,
			"status":status,
			"filename":filename,

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const remove = async (id:number) => {
	try {
		const response = await axios.delete(
          baseURL + "/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}