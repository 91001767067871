import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/mitra';


@Options({
    name: 'app-mitra',
    components: {
        
    },
	data() {
      return {
        title: "List Mitra PMI",
		mode:1
      };
    },
})
export default class Mitra extends Vue {
	public name: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public logo:any;
	private toast = useToast();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}

	
	public async submit(): Promise<void>{
        try {
		
			if(this.name == "")
			{
				this.toast.error('Nama Mitra harus diisi');
				return;
			}
			
			if(this.logo == "")
			{
				this.toast.error('Logo Mitra harus diisi');
				return;
			}
           
            const response = await create(this.name, this.logo, this.urutan, this.status);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Mitra PMI berhasil disimpan');
				this.name = "";
				this.logo = "";
				this.$router.push("/mitra");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
