import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/faq';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-faq',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "List FAQ",
		mode:1
      };
    },
})
export default class Faq extends Vue {
	public question: string = '';
	public answer: string = '';
	private toast = useToast();
	
	public async submit(): Promise<void>{
        try {
			if(this.question == "")
			{
				this.toast.error('Pertanyaan harus diisi');
				return;
			}
			
			if(this.answer == "")
			{
				this.toast.error('Jawaban harus diisi');
				return;
			}
			
			
            const response = await create(this.question, this.answer);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('FAQ berhasil disimpan');
				this.question = "";
				this.answer = "";
				this.$router.push("/faq");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
