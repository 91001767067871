

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>User</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					<li class="breadcrumb-item">
                        <router-link to="/user"
                            >List User</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        User
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Tambah User</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      <ckeditor :editor="editor" v-model="question" :config="editorConfig"></ckeditor>
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Email </label
                                        >
                                        <div class="col-sm-10">
                                         
										 <input
                                                type="email"
												v-model="email"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
									
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Nama Lengkap  </label
                                        >
                                        <div class="col-sm-10">
										 <input
                                                type="text"
												v-model="full_name"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Nama Lengkap"
                                            />
                                        </div>
									  </div>	
										
										 <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Telepon </label
                                        >
                                        <div class="col-sm-10">
										 <input
                                                type="tel"
												v-model="phone"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Telepon"
                                            />
                                        </div>
                                    </div>
									
									 <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Password </label
                                        >
                                        <div class="col-sm-10">
										 <input
                                                type="password"
												v-model="password"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
									
										<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Role</label
                                        >
                                        <div class="col-sm-10">
											<select  v-model="role" class="form-control">
												<option value="Admin">Admin</option>
												<option value="User">User</option>
											</select>
                                        </div>
                                    </div>
									
										<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Status</label
                                        >
                                        <div class="col-sm-10">
											<select  v-model="status" class="form-control">
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</select>
                                        </div>
                                    </div>
                                  
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
											@click="submit"
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
