<!-- Navbar -->
<nav
    id="main-header"
    class="main-header navbar navbar-expand"
    :class="navbarVariant"
>
    <ul class="navbar-nav">
        <li class="nav-item">
            <button class="nav-link" role="button" @click="onToggleMenuSidebar">
                <i class="fas fa-bars"></i>
            </button>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <router-link to="/" class="nav-link">
                {{ $t("labels.home") }}
            </router-link>
        </li>
        
    </ul>

    <ul class="navbar-nav ml-auto">
     
       
        <user-dropdown></user-dropdown>
        
    </ul>
</nav>
