import {Options, Vue} from 'vue-class-component';
import {getContact, updateContact} from '@/services/contact';
import {useToast} from 'vue-toastification';


@Options({})
export default class Contact extends Vue {
	public address: string = '';
	public email: string = '';
	public phone: string = '';
	public facebook: string = '';
	public youtube: string = '';
	public twitter: string = '';
	public instagram: string = '';
	private toast = useToast();

	public mounted(): void {
        this.loadContact();
    }	
	
	public async submit(): Promise<void>{
        try {
			if(this.address == "")
			{
				this.toast.error('Alamat harus diisi');
				return;
			}
			if(this.email == "")
			{
				this.toast.error('Email harus diisi');
				return;
			}
			if(this.phone == "")
			{
				this.toast.error('Telp harus diisi');
				return;
			}
		
           
            const contacts = await updateContact(this.address, this.phone, this.email, this.facebook, this.youtube, this.twitter, this.instagram);
			if(contacts.status == false)
			{
				this.toast.error(contacts.message);
			}
			else
			{
				this.toast.success('Kontak berhasil diubah');
				this.loadContact();
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
	
	public async loadContact() : Promise<void>{
        try {
           
            const contacts = await getContact();
			if(contacts.status == false)
			{
				this.toast.error(contacts.message);
			}
			else
			{
				this.address = contacts.data.address;
				this.email = contacts.data.email;
				this.phone = contacts.data.phone;
				this.facebook = contacts.data.facebook;
				this.youtube = contacts.data.youtube;
				this.twitter = contacts.data.twitter;
				this.instagram = contacts.data.instagram;
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
