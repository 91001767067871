import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {update, find} from '@/services/basic_principle';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useRoute } from 'vue-router';

@Options({
    title: 'app-basic_principle',
    components: {
        QuillEditor,
    },
	data() {
      return {
        title: "List Prinsip Dasar",
		mode:1
      };
    },
})
export default class BasicPrinciple extends Vue {
	public title: string = '';
	public content: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public filename: string = '';
	public photo:any;
	private toast = useToast();
	private route = useRoute();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.photo = reader.result.toString().split(',')[1];
        };
		
	}
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Pelayanan tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.filename = response.data.photo;
				this.title = response.data.title;
				this.urutan = response.data.sort;
				this.status = response.data.status;
				this.content = response.data.content;
				
				
				
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}

	
	public async submit(): Promise<void>{
        try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Prinsip Dasar tidak valid');
				this.$router.push("/");
				return;
			}
			if(this.title == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.content == "")
			{
				this.toast.error('Isi harus diisi');
				return;
			}
			
		
			if(this.photo == "")
			{
				this.toast.error('Foto harus diisi');
				return;
			}
           
            const response = await update(id, this.title, this.content, this.photo, this.urutan, this.status, this.filename);
			console.log(response);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Prinsip Dasar berhasil diubah');
				this.$router.push("/basic_principle");
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
