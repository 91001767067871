import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/contact";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const getContact = async () => {
	try {
		const response = await axios.get(
          baseURL + "/1",
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const updateContact = async (address:string, phone:string, email:string, facebook:string, youtube:string, twitter:string, instagram:string) => {
	try {
		const response = await axios.put(
            baseURL + "/1",
		  {
			"address":address,
			"phone":phone,
			"email":email,
			"facebook":facebook,
			"youtube":youtube,
			"twitter":twitter,
			"instagram":instagram
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}