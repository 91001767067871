

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Ganti Password</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					
                    <li class="breadcrumb-item active">
                        Ganti Password
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Ganti Password</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Password </label
                                        >
                                        <div class="col-sm-10">
                                         
										 <input
                                                type="password"
												v-model="password"
                                                class="form-control"
                                                id="inputName"
                                                placeholder="Password"
                                            />
                                        </div>
                                    </div>
									
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Konfirmasi Password  </label
                                        >
                                        <div class="col-sm-10">
										 <input
                                                type="password"
												v-model="confirm_password"
                                                class="form-control"
                                                id="inputNamex"
                                                placeholder="Konfirmasi Password"
                                            />
                                        </div>
									  </div>	
										
									
									
								
										
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
                                                @click="submit"
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
