import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import {load, create, update, find, remove} from '@/services/program';
import {useToast} from 'vue-toastification';
import {environment} from '../../../../environments/environment';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import JsonExcel from 'vue-json-excel';

@Options({
    name: 'app-program',
    components: {
        JsonExcel,
    },
	data() {
      return {
        title: "List Program",
		mode:1,
		imageURL:environment.imageURL
      };
    },
})

export default class Program extends Vue {
	private toast = useToast();
	records: any = [];

	public mounted(): void {
        this.loadData();
		setTimeout(function() {
			$('table').DataTable();
		}, 200);
		
    }
	
	public async remove(id:number) : Promise<void>{
        try {
			if(!confirm('Apakah anda ingin menghapus data ini?')) return;
            const response = await remove(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success("Program Donasi berhasil dihapus");
				this.loadData();
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
	
	public async loadData() : Promise<void>{
        try {
          
            const response = await load();
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.records = response.data;
				
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
