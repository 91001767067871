import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/donation";
const baseURLProgram =  environment.apiURL + "/program";
const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const loadProgram = async () => {
	try {
		const response = await axios.get(
          baseURLProgram
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}
export const filter = async(tglawal:string, tglakhir:string, program:string, tipe:string) =>{
    try {
        const response = await axios.post(
            baseURL + "/filter",
            {
                "tglawal":tglawal,
                "tglakhir":tglakhir,
                "program":program,
                "tipe":tipe
            }
        );

        return response.data;
    } catch (error: any) {
        throw getError(error);
    }
}
