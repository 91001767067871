<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Donasi</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Donasi
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                       <h5>{{this.title}}</h5>
                    </div>

                   
                    <!-- /.card-header -->
                    <div class="card-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label for="inputEmail" class="col-sm-2 col-form-label">Dari Tanggal </label>
                                <div class="col-sm-10">
                                    <input type="date" v-model="from" class="form-control" id="tglawal" placeholder="" />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail" class="col-sm-2 col-form-label">Sampai Tanggal </label>
                                <div class="col-sm-10">
                                    <input type="date" v-model="to" class="form-control" id="tglakhir" placeholder="" />
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="inputEmail" class="col-sm-2 col-form-label">Program </label>
                                <div class="col-sm-10">
                                    <!--select class="form-control" v-model="program" id="program">
                                            <option value="">Semua Program</option>
                                        <option value="{{ el, id }}" v-for="(el, index) in recordsProgram" :key="index">
                                            {{ el.title }}
                                        </option>
                                    </select-->
                                    <select class="form-control" v-model="program" id="program">
                                        <option value="">Semua Program</option>
                                        <option v-for="(el, index) in recordsProgram" :key="index" :value="el.title">
                                            {{ el.title }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                                <div class="form-group row">
                                    <label for="inputEmail" class="col-sm-2 col-form-label">Tipe Program</label>
                                    <div class="col-sm-10">
                                        <select v-model="tipe" class="form-control" id="tipe">
                                            <option value="">Semua Tipe</option>
                                            <option value="Donasi Umum">Donasi Umum</option>
                                            <option value="Donasi Program">Donasi Program</option>
                                            <option value="Donasi Khusus">Donasi Khusus</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <div class="offset-sm-2 col-sm-10">
                                        <button type="button" @click="submit" class="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>

                        </form>

						<input type="hidden" value="{{this.records.length}}"/>
						<div class="table-responsive">
							<table class="table table-bordered table-striped table-hover">
								<thead>
									<tr>
										<th>No</th>
										<th>User</th>
										<th>Program Donasi</th>
										<th>Tanggal</th>
										<th>Jumlah</th>
										<th>Status</th>
										<th>Metode Bayar</th>
									</tr>
								</thead>
								<tbody>
								<template v-for="(el, index) in records" :key="index">
									<tr>
										<td>{{index + 1}}</td>
										<td v-html="el.full_name"></td>
										<td v-html="el.program"></td>
										<td v-html="el.createdAt"></td>
										<td v-html="'Rp ' + el.amount"></td>
										<td v-html="el.payment_status"></td>
										<td v-html="el.payment_method"></td>
									</tr>
								</template>
								
								</tbody>
							</table>
						</div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
           
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
