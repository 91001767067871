import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/user";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const find = async (id:string) => {
	try {
		const response = await axios.get(
          baseURL + "/" + id
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const create = async ( email:string, full_name:string, phone:string, role:string, password:string, status:string) => {
	try {
		const response = await axios.post(
          baseURL,
		  {
			"email":email,
			"full_name":full_name,
			"phone":phone,
			"role":role,
            "is_special": role == "Admin" ? 1 : 0,
			"password":password,
			"status":status,
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const update = async (id:string, email:string, full_name:string, phone:string, role:string, status:string) => {
	try {
		const response = await axios.put(
          baseURL + "/" +id ,
		  {
			"username":email,
			"email":email,
			"full_name":full_name,
			"phone":phone,
			"role":role,
			
			"status":status,

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const changePassword = async (token:string, password:string) => {
	try {
		const response = await axios.put(
          baseURL + "/password" ,
		  {
			"token":token,
			"password":password,
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const remove = async (id:number) => {
	try {
		const response = await axios.delete(
          baseURL + "/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const publish = async (id:number) => {
	try {
		const response = await axios.post(
          baseURL + "/publish/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}