import {Options, Vue} from 'vue-class-component';
import {loginByAuth} from '@/services/auth';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {PfButton, PfCheckbox} from '@profabric/vue-components';

@Options({
    components: {
        'app-input': Input,
        'pf-checkbox': PfCheckbox,
        'pf-button': PfButton
    }
})
export default class Login extends Vue {
    private appElement: HTMLElement | null = null;
    public email: string = '';
    public password: string = '';
    public rememberMe: boolean = false;
    public isAuthLoading: boolean = false;
    public isFacebookLoading: boolean = false;
    public isGoogleLoading: boolean = false;
    private toast = useToast();

    public mounted(): void {
        this.appElement = document.getElementById('app') as HTMLElement;
        this.appElement.classList.add('login-page');
    }

    public unmounted(): void {
        (this.appElement as HTMLElement).classList.remove('login-page');
    }

    public async loginByAuth(): Promise<void> {
        try {
			if(this.email == "")
			{
				this.toast.error('Email harus diisi');
				return;
			}
			
			if(this.password == "")
			{
				this.toast.error('Password harus diisi');
				return;
			}
		
            this.isAuthLoading = true;
            const users = await loginByAuth(this.email, this.password);
			if(users.status == false)
			{
				this.toast.error(users.message);
			}
			else
			{
				this.$store.dispatch('auth/login', users.data.accessToken);
				this.toast.success('Login berhasil');
				this.$router.push('/');
			}
			this.isAuthLoading = false;
        } catch (error: any) {
            this.toast.error(error.message);
            this.isAuthLoading = false;
        }
    }

  
}
