<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Publikasi</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
					<li class="breadcrumb-item">
                        <router-link to="/publication"
                            >List Publikasi</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Publikasi
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Lihat Publikasi</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Judul</label
                                        >
                                        <div class="col-sm-10">
                                            <input
											 v-model="name"
											 readonly
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Author</label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="author"
                                                type="text"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Tanggal Publikasi</label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="date"
                                                type="date"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Singkat</label
                                        >
                                        <div class="col-sm-10">
                                           <p v-html="short_content"></p>
                                        </div>
                                    </div>
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Isi Lengkap</label
                                        >
                                         <div class="col-sm-10">
                                           <p v-html="full_content"></p>
                                        </div>
                                    </div>
									
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Foto</label
                                        >
                                        <div class="col-sm-10">
                                             <div class="col-sm-10">
                                             <img style='width:150px;height:150px;' v-bind:src="imageURL + '/' + filename"/>
                                        </div>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputName"
											
                                            class="col-sm-2 col-form-label"
                                            >Youtube</label
                                        >
                                        <div class="col-sm-10">
                                            <input
											readonly
											 v-model="youtube"
                                                type="url"
                                                class="form-control"
                                                id="inputName"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Headline</label
                                        >
                                        <div class="col-sm-10">
											<select readonly v-model="headline" class="form-control">
												<option value="0">Tidak</option>
												<option value="1">Ya</option>
											
											</select>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Status</label
                                        >
                                        <div class="col-sm-10">
											<select readonly v-model="status" class="form-control">
												<option value="1">Aktif</option>
												<option value="0">Tidak Aktif</option>
											</select>
                                        </div>
                                    </div>
									<div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Urutan</label
                                        >
                                        <div class="col-sm-10">
                                            <input
												min="1"
												readonly
												 v-model="urutan"
                                                type="number"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Urutan"
                                            />
                                        </div>
                                    </div>
                                    
                                   
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
