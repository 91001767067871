import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/basic_principle';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    title: 'app-basic_principle',
    components: {
        QuillEditor,
    },
	data() {
      return {
        title: "List Prinsip Dasar",
		mode:1
      };
    },
})
export default class BasicPrinciple extends Vue {
	public title: string = '';
	public content: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public photo:any;
	private toast = useToast();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.photo = reader.result.toString().split(',')[1];
        };
		
	}

	
	public async submit(): Promise<void>{
        try {
		
			if(this.title == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.content == "")
			{
				this.toast.error('Isi harus diisi');
				return;
			}
			
		
			if(this.photo == "")
			{
				this.toast.error('Foto harus diisi');
				return;
			}
           
            const response = await create(this.title, this.content, this.photo, this.urutan, this.status);
			console.log(response);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Prinsip Dasar berhasil disimpan');
				this.$router.push("/basic_principle");
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
