<div class="login-box">
    <!-- /.login-logo -->
    <div class="card card-outline card-primary">
        <div class="card-header text-center">
            <router-link to="/" class="h1"
                ><b>{{ $t("labels.admin") }}</b> PMI
                </router-link
            >
        </div>
        <div class="card-body">
            <p class="login-box-msg">{{ $t("labels.signInToStart") }}</p>

            <form @submit.prevent="loginByAuth">
                <app-input
                    icon="envelope"
                    class="mb-3"
                    type="email"
                    placeholder="Email"
                    autocomplete="off"
					required="required"
                    v-model="email"
                />
                <app-input
                    icon="lock"
                    class="mb-3"
                    type="password"
					required="required"
                    placeholder="Password"
                    autocomplete="off"
                    v-model="password"
                />

                <div class="row">
                    <div class="col-7">
                       
                    </div>
                    <!-- /.col -->
                    <div class="col-5">
                        <pf-button
                            type="submit"
                            block="true"
                            :loading="isAuthLoading"
                        >
                            {{ $t("labels.signIn") }}
                        </pf-button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
        </div>
        <!-- /.card-body -->
    </div>
    <!-- /.card -->
</div>
