import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {find, update} from '@/services/channel';
import { useRoute } from 'vue-router';

@Options({
    name: 'app-channel',
    components: {
        
    },
	data() {
      return {
        title: "List Payment Channel",
		mode:1
      };
    },
})
export default class Channel extends Vue {
	public name: string = '';
	public kategori: string = '1';
	public filename: string = '1';
	public logo:any;
	private toast = useToast();
	
	private route = useRoute();
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Payment Channel tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.filename = response.data.logo;
				this.name = response.data.name;
				this.kategori = response.data.kategori;
				
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}

	
	public async submit(): Promise<void>{
        try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Payment Channel tidak valid');
				this.$router.push("/");
				return;
			}
		
			if(this.name == "")
			{
				this.toast.error('Nama Payment Channel harus diisi');
				return;
			}
			
			

			if(this.kategori == "")
			{
				this.toast.error('Kategori harus diisi');
				return;
			}
           
            const response = await update(id, this.name, this.logo, this.kategori, this.filename);
			if(response.kategori == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Payment Channel berhasil diubah');
				this.name = "";
				this.logo = "";
				this.$router.push("/channel");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
