import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {update, find} from '@/services/newsletter';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill'
import {environment} from '../../../../environments/environment';

@Options({
    name: 'app-newsletter',
    components: {
		QuillEditor,
    },
	data() {
      return {
        title: "View Newsletter",
		mode:1,
		imageURL:environment.imageURL
      };
    },
})
export default class Newsletter extends Vue {
	public name: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public content: string = '';
	public filename: string = '';
	public logo:string = '';
	private toast = useToast();
	private route = useRoute();
	
	uploadFile(e:any)
	{
		const reader = new FileReader()
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            console.log(reader.result);
			this.logo = reader.result.toString().split(',')[1];
        };
		
	}
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Newsletter tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.filename = response.data.photo;
				this.name = response.data.title;
				this.urutan = response.data.sort;
				this.status = response.data.status;
				this.content = response.data.content;
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	
}
