import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21062250"),n=n(),_popScopeId(),n)
const _hoisted_1 = { slot: "button" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "input-group-append" }
const _hoisted_5 = {
  class: "menu",
  slot: "menu"
}
const _hoisted_6 = {
  key: 0,
  class: "nothing-found"
}
const _hoisted_7 = {
  key: 1,
  class: "list-group"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "search-path" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_pf_dropdown = _resolveComponent("pf-dropdown")

  return (_openBlock(), _createBlock(_component_pf_dropdown, {
    "hide-arrow": "",
    openOnButtonClick: false,
    isOpen: _ctx.isDropdownOpen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", {
            class: "form-control form-control-sidebar",
            type: "text",
            placeholder: "Search",
            "aria-label": "Search",
            value: _ctx.searchText,
            onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.handleSearchTextChange && _ctx.handleSearchTextChange(...args)))
          }, null, 40, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "btn btn-sidebar",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.handleIconClick()))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass({
                            fas: true,
                            'fa-search': _ctx.searchText.length === 0,
                            'fa-times': _ctx.searchText.length > 0,
                            'fa-fw': true
                        })
              }, null, 2)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.foundMenuItems.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, " No Element found "))
          : _createCommentVNode("", true),
        (_ctx.foundMenuItems.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.foundMenuItems, (menuItem) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  to: menuItem.path,
                  class: "list-group-item",
                  onClick: _ctx.handleMenuItemClick
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "search-title",
                      innerHTML: _ctx.boldString(menuItem.name, _ctx.searchText)
                    }, null, 8, _hoisted_8),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(menuItem.name), 1)
                  ]),
                  _: 2
                }, 1032, ["to", "onClick"]))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}