import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {find, update} from '@/services/user';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { useRoute } from 'vue-router';

@Options({
    name: 'app-user',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "List User",
		mode:1
      };
    },
})
export default class User extends Vue {
	public email: string = '';
	public password: string = '';
	public full_name: string = '';
	public phone: string = '';
	public role: string = '';
	public status:string = '';
	private toast = useToast();
	private route = useRoute();
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('User tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.email = response.data.email;
				this.role = response.data.role;
				this.status = response.data.active;
				this.full_name = response.data.full_name;
				this.phone = response.data.phone_number;
				this.role = response.data.role;
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	public async submit(): Promise<void>{
        try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('User tidak valid');
				this.$router.push("/");
				return;
			}
		
			if(this.email == "")
			{
				this.toast.error('Email harus diisi');
				return;
			}
			
			
			if(this.phone == "")
			{
				this.toast.error('Telepon harus diisi');
				return;
			}
			
			if(this.role == "")
			{
				this.toast.error('Role User harus diisi');
				return;
			}
			
			
            const response = await update(id, this.email, this.full_name, this.phone, this.role,  this.status);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('User berhasil disimpan');
				this.$router.push("/user");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
