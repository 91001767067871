<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Info PMI</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Info PMI
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                      <h5>Edit Info PMI</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                      
                            
                                <form class="form-horizontal">
								 <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Tentang PMI </label
                                        >
                                        <div class="col-sm-10">
                                              <div class="col-sm-10">
                                         
											<QuillEditor toolbar="full" theme="snow" 
										
											v-model:content="about_us" contentType="html"/>
										
                                        </div>
                                    </div>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
                                    </div>
								
                                    <div class="form-group row">
                                        <label
                                            for="inputName"
                                            class="col-sm-2 col-form-label"
                                            >Visi </label
                                        >
                                        <div class="col-sm-10">
                                              <div class="col-sm-10">
                                         
											<QuillEditor toolbar="full" theme="snow" 
										
											v-model:content="vision" contentType="html"/>
										
                                        </div>
                                    </div>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
                                    </div>
                                    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Misi </label
                                        >
                                         <div class="col-sm-10">
                                              <div class="col-sm-10">
                                         
											<QuillEditor toolbar="full" theme="snow" 
										
											v-model:content="mission" contentType="html"/>
										
                                        </div>
                                    </div>
									<p>&nbsp;</p>
									<p>&nbsp;</p>
                                    </div>
									
									    <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            >Struktur Organisasi </label
                                        >
                                        <div class="col-sm-10">
                                            <input
												
                                                type="file"
												v-on:change="uploadFile"
                                                class="form-control"
                                                id="inputEmail"
                                                placeholder="Struktur Organisasi"
                                            />
                                        </div>
                                    </div>
									
									 <div class="form-group row">
                                        <label
                                            for="inputEmail"
                                            class="col-sm-2 col-form-label"
                                            ></label
                                        >
                                        <div class="col-sm-10">
                                             <img v-if="filename != null && filename != ''" style='width:150px;height:150px;' v-bind:src="imageURL + '/' + filename"/>
                                        </div>
                                    </div>
                                  
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <button
                                                type="button"
												@click="submit"
                                                class="btn btn-primary"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
       
    <!-- /.container-fluid -->
</section>
