import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import store from '@/store/index';

import Main from '@/modules/main/main.vue';
import Login from '@/modules/login/login.vue';
import Register from '@/modules/register/register.vue';

import Dashboard from '@/pages/dashboard/dashboard.vue';
import Contact from '@/pages/contact/contact.vue';
import Content from '@/pages/content/content.vue';

import Password from '@/pages/user/password/password.vue';
import BlastEmail from '@/pages/user/blastEmail/blastEmail.vue';

import Faq from '@/pages/faq/list/faq.vue';
import CreateFaq from '@/pages/faq/create/faq.vue';
import UpdateFaq from '@/pages/faq/update/faq.vue';

import Donation from '@/pages/donation/list/donation.vue';

import User from '@/pages/user/list/user.vue';
import CreateUser from '@/pages/user/create/user.vue';
import UpdateUser from '@/pages/user/update/user.vue';

import Mitra from '@/pages/mitra/list/mitra.vue';
import CreateMitra from '@/pages/mitra/create/mitra.vue';
import UpdateMitra from '@/pages/mitra/update/mitra.vue';

import Channel from '@/pages/channel/list/channel.vue';
import CreateChannel from '@/pages/channel/create/channel.vue';
import UpdateChannel from '@/pages/channel/update/channel.vue';

import Slider from '@/pages/slider/list/slider.vue';
import CreateSlider from '@/pages/slider/create/slider.vue';
import UpdateSlider from '@/pages/slider/update/slider.vue';
import ViewSlider from '@/pages/slider/view/slider.vue';

import Service from '@/pages/service/list/service.vue';
import CreateService from '@/pages/service/create/service.vue';
import UpdateService from '@/pages/service/update/service.vue';
import ViewService from '@/pages/service/view/service.vue';

import BasicPrinciple from '@/pages/basic_principle/list/basic_principle.vue';
import CreateBasicPrinciple from '@/pages/basic_principle/create/basic_principle.vue';
import UpdateBasicPrinciple from '@/pages/basic_principle/update/basic_principle.vue';

import Newsletter from '@/pages/newsletter/list/newsletter.vue';
import CreateNewsletter from '@/pages/newsletter/create/newsletter.vue';
import UpdateNewsletter from '@/pages/newsletter/update/newsletter.vue';
import ViewNewsletter from '@/pages/newsletter/view/newsletter.vue';

import Program from '@/pages/program/list/program.vue';
import CreateProgram from '@/pages/program/create/program.vue';
import UpdateProgram from '@/pages/program/update/program.vue';
import ViewProgram from '@/pages/program/view/program.vue';

import Publication from '@/pages/publication/list/publication.vue';
import CreatePublication from '@/pages/publication/create/publication.vue';
import UpdatePublication from '@/pages/publication/update/publication.vue';
import ViewPublication from '@/pages/publication/view/publication.vue';

import Toc from '@/pages/toc/list/toc.vue';
import CreateToc from '@/pages/toc/create/toc.vue';
import UpdateToc from '@/pages/toc/update/toc.vue';

import Profile from '@/pages/profile/profile.vue';
import ForgotPassword from '@/modules/forgot-password/forgot-password.vue';
import RecoverPassword from '@/modules/recover-password/recover-password.vue';
import PrivacyPolicy from '@/modules/privacy-policy/privacy-policy.vue';
import SubMenu from '@/pages/main-menu/sub-menu/sub-menu.vue';
import Blank from '@/pages/blank/blank.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true
        },
		children:[
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: Dashboard,
				meta: {
					requiresAuth: true
				}
			},
			 {
				path: '/content',
				name: 'Info PMI',
				component: Content,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/password',
				name: 'Ganti Password',
				component: Password,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/blast_email',
				name: 'Blast Email',
				component: BlastEmail,
				meta: {
					requiresAuth: true
				}
			},
		
			 {
				path: '/contact',
				name: 'Contact',
				component: Contact,
				meta: {
					requiresAuth: true
				}
			},
			
			
			{
				path: '/basic_principle',
				name: 'BasicPrinciple',
				component: BasicPrinciple,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-basic_principle',
				name: 'Create Prinsip Dasar',
				component: CreateBasicPrinciple,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-basic_principle/:id',
				name: 'Edit Prinsip Dasar',
				component: UpdateBasicPrinciple,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/service',
				name: 'Service',
				component: Service,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-service',
				name: 'Create Service',
				component: CreateService,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-service/:id',
				name: 'Edit Service',
				component: UpdateService,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/view-service/:id',
				name: 'Lihat Service',
				component: CreateService,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/donation',
				name: 'Donation',
				component: Donation,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/faq',
				name: 'FAQ',
				component: Faq,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-faq',
				name: 'Create FAQ',
				component: CreateFaq,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-faq/:id',
				name: 'Update FAQ',
				component: UpdateFaq,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/mitra',
				name: 'Mitra',
				component: Mitra,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-mitra',
				name: 'Create Mitra',
				component: CreateMitra,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-mitra/:id',
				name: 'Edit Mitra',
				component: UpdateMitra,
				meta: {
					requiresAuth: true
				}
			},
			
			{
				path: '/channel',
				name: 'Channel',
				component: Channel,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-channel',
				name: 'Create Channel',
				component: CreateChannel,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-channel/:id',
				name: 'Edit Channel',
				component: UpdateChannel,
				meta: {
					requiresAuth: true
				}
			},
			
			{
				path: '/slider',
				name: 'Slider',
				component: Slider,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-slider',
				name: 'Create Slider',
				component: CreateSlider,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-slider/:id',
				name: 'Edit Slider',
				component: UpdateSlider,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/newsletter',
				name: 'Newsletter',
				component: Newsletter,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-newsletter',
				name: 'Create Newsletter',
				component: CreateNewsletter,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-newsletter/:id',
				name: 'Edit Newsletter',
				component: UpdateNewsletter,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/view-newsletter/:id',
				name: 'Lihat Newsletter',
				component: ViewNewsletter,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/program',
				name: 'Program',
				component: Program,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/view-program/:id',
				name: 'Lihat Program Donasi',
				component: ViewProgram,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-program',
				name: 'Create Program',
				component: CreateProgram,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-program/:id',
				name: 'Edit Program',
				component: UpdateProgram,
				meta: {
					requiresAuth: true
				}
			},
			
			{
				path: '/publication',
				name: 'Publikasi',
				component: Publication,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-publication',
				name: 'Create Publikasi',
				component: CreatePublication,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-publication/:id',
				name: 'Update Publikasi',
				component: UpdatePublication,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/view-publication/:id',
				name: 'Lihat Publikasi',
				component: ViewPublication,
				meta: {
					requiresAuth: true
				}
			},
			{
			
				path: '/toc',
				name: 'Toc',
				component: Toc,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/create-toc',
				name: 'Create Toc',
				component: CreateToc,
				meta: {
					requiresAuth: true
				}
			},
			{
				path: '/update-toc/:id',
				name: 'Edit Toc',
				component: UpdateToc,
				meta: {
					requiresAuth: true
				}
			},
			
			{
			
				path: '/user',
				name: 'User',
				component: User,
				meta: {
					requiresAuth: true
				}
			},
			{
			
				path: '/create-user',
				name: 'Create User',
				component: CreateUser,
				meta: {
					requiresAuth: true
				}
			},
			{
			
				path: '/update-user/:id',
				name: 'Edit User',
				component: UpdateUser,
				meta: {
					requiresAuth: true
				}
			},
		],
        
    },
	{
        path: '/blank',
        name: 'Blank',
        component: Blank,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    if (to.meta.requiresAuth && !store.getters['auth/token']) {
        next('/login');
		console.log(to,next);
    } else if (to.meta.requiresUnauth && !!store.getters['auth/token']) {
        next();
		console.log(to,next);
    } else {
        next();
		console.log(to,next);
    }
});

export default router;
