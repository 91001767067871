import {IUser} from '@/interfaces/user';
import {Options, Vue} from 'vue-class-component';
import MenuItem from '@/components/menu-item/menu-item.vue';
import {PfImage} from '@profabric/vue-components';
import SidebarSearch from '@/components/sidebar-search/sidebar-search.vue';
import {i18n} from '@/translation';

@Options({
    name: 'app-menu-sidebar',
    components: {
        'app-menu-item': MenuItem,
        'app-sidebar-search': SidebarSearch,
        'pf-image': PfImage
    }
})
export default class MenuSidebar extends Vue {
    public menu = MENU;

    get user(): IUser {
        return this.$store.getters['auth/user'];
    }

    get sidebarSkin() {
        return this.$store.getters['ui/sidebarSkin'];
    }
}

export const MENU = [
    {
        name: i18n.global.t('labels.home'),
        path: '/'
    },
    {
        name: 'Dashboard',
        path: '/dashboard'
    },
	{
        name: 'Info PMI',
        path: '/content'
    },
    {
        name: 'Kontak PMI',
        path: '/contact'
    },
	{
        name: 'FAQ',
        path: '/faq'
    },
	{
        name: 'Mitra PMI',
        path: '/mitra'
    },
	{
        name: 'Newsletter',
        path: '/newsletter'
    },
	{
        name: 'Payment Channel',
        path: '/channel'
    },
	{
        name: 'Program Donasi',
        path: '/program'
    },
	{
        name: 'Publikasi',
        path: '/publication'
    },
	{
        name: 'Pelayanan',
        path: '/service'
    },
	{
        name: 'Slider',
        path: '/slider'
    },
	{
        name: 'Syarat dan Ketentuan',
        path: '/toc'
    },
    {
        name: 'Donasi',
        path: '/donation'
    },
	{
        name: 'Prinsip Dasar',
        path: '/basic_principle'
    },
	{
        name: 'User',
        path: '/user'
    },
    {
        name: 'Ganti Password',
        path: '/password'
    },
    {
        name: 'Blast Email',
        path: '/blast_email'
    },
     {
        name: 'Logout',
        path: '/login'
    },

];
