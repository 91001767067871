<template>
  <div class="container">
    <Bar v-if="loaded" :data="chartData" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { getDonasibulan } from "@/services/dashboard";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  data: () => ({
    loaded: false,
    chartData: null
  }),
  async mounted () {
    this.loaded = false

    try {
      const response = await getDonasibulan()
      if (response.data && response.data.length > 0) {
        // Proses hasil pemanggilan API dan atur grafik
        this.chartData = {
          labels: response.data.map(item => `Bulan ${item.bulan}`),
          datasets: [
            {
              label: 'Total Donation Amount',
              data: response.data.map(item => item.total),
              backgroundColor: ['rgba(75, 192, 192, 0.5)', 'rgba(75, 192, 192, 0.5)', 'rgba(75, 192, 192, 0.5)'],
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              options: {
                scales: {
                  x: {
                    grid: {
                      color: 'rgba(255, 255, 255, 0.1)',
                    },
                  },
                  y: {
                    grid: {
                      color: 'rgba(255, 255, 255, 0.1)',
                    },
                  },
                },
              },
            }
            // Mungkin tambahkan dataset lain jika dibutuhkan
          ]
        };
      } else {
        console.error('Data from the API is undefined or empty.');
      }
      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
}
</script>