<!-- Content Header (Page header) -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">{{ $t("labels.dashboard") }}</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row" style="display: flex">
            <div class="col-lg-4 col-6">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>{{ this.tP }}</h3>

                        <p>Jumlah Program Donasi</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <router-link to="/" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>{{ this.tD }}</h3>

                        <p>Donasi Berlangsung</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <router-link to="/" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{ this.donatur }}</h3>

                        <p>Donatur</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <router-link to="/" class="small-box-footer"
                        >More info <i class="fas fa-arrow-circle-right"></i
                    ></router-link>
                </div>
            </div>
            <!-- ./col -->

            <div class="col-lg-8 ">
                <div class="card">
                        <h4 class="header-title mb-3"></h4>
                        <div id="chart_trx">
                            <ChartComponent />
                            <canvas ref="chartCanvas"></canvas>
                        </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card">
                        <h4 class="header-title mb-3"></h4>
                        <div id="tabel_trx">
                            <PieComponent />
                            <canvas ref="chartCanvas"></canvas>
                        </div>
                </div>
            </div>
            <div class="col-lg-4" >
                <div class="card">
                        <h4 class="header-title mb-3"></h4>
                        <div id="chart_pie">
                            <ProgramBulan />
                        </div>
                </div>
            </div>
            <div class="col-lg-8" >
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title mb-3"></h4>
                    <input type="hidden" value="{{this.records.length}}"/>
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-hover" ref="dataTable">
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>List Program Donasi</th>
                                <th>Total Donasi</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(el, index) in records" :key="index">
                                <tr>
                                    <td>{{index + 1}}</td>
                                    <td v-html="el.program"></td>
                                    <td>{{ formatCurrency(el.total) }}</td>
                                </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
            <!-- ./col -->
        </div>
    </div>
        <!-- /.row -->
    <!-- /.container-fluid -->
</section>
<!-- /.content -->
