import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import {load, create, update, find, remove, publish} from '@/services/newsletter';
import {useToast} from 'vue-toastification';
import {environment} from '../../../../environments/environment';
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';

@Options({
    name: 'app-newsletter',
    components: {
        
    },
	data() {
      return {
        title: "List Newsletter",
		mode:1,
		imageURL:environment.imageURL
      };
    },
})

export default class Newsletter extends Vue {
	private toast = useToast();
	records: any = [];

	public mounted(): void {
        this.loadData();
		setTimeout(function() {
			$('table').DataTable();
		}, 200);
    }
	
	public async remove(id:number) : Promise<void>{
        try {
			if(!confirm('Apakah anda ingin menghapus data ini?')) return;
            const response = await remove(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success("Newsletter berhasil dihapus");
				this.loadData();
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
	
	public async publish(id:number) : Promise<void>{
        try {
           
            const response = await publish(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success("Success");
				this.loadData();
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
	
	public async loadData() : Promise<void>{
        try {
          
            const response = await load();
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.records = response.data;
				
				
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
