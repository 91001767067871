<template>
  <div class="container">
    <Pie v-if="loaded" :data="chartData" />
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import { getDonasiprogram } from "@/services/dashboard";
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

export default {
  name: 'PieChart',
  components: { Pie },
  data: () => ({
    loaded: false,
    chartData: null
  }),
  async mounted () {
    this.loaded = false

    try {
      const response = await getDonasiprogram()
      if (response.data && response.data.length > 0) {
        this.chartData = {
          labels: response.data.map(item => `${item.program}`),
          datasets: [
            {
              label: 'Donasi Program',
              data: response.data.map(item => item.total),
              backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(153, 102, 255, 1)',
              ],
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1
            }
          ]
        };
      } else {
        console.error('Data from the API is undefined or empty.');
      }
      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
