import axios from "axios";
import Input from '@/components/input/input.vue';
import {environment} from '../../environments/environment';
const baseURL =  environment.apiURL + "/publication";

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const load = async () => {
	try {
		const response = await axios.get(
          baseURL,{
            headers: {
                'Special': '1',
            }, // Pass the headers object here
          }
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const find = async (id:string) => {
	try {
		const response = await axios.get(
          baseURL + "/" + id
		  
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const create = async (title:string, logo:string, author:string, date:string, youtube:string, full_content:string, short_content:string, urutan:number, status:string, headline:string) => {
	try {
		const response = await axios.post(
          baseURL,
		  {
			"title":title,
			"short_content":short_content,
			"full_content":full_content,
			"author":"author",
			"publication_date":date,
			"youtube":youtube,
			"photo":logo,
			"sort":urutan,
			"headline":headline,
			"status":status,
			
		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const update = async (id:string, title:string, logo:string, author:string, date:string, youtube:string, full_content:string, short_content:string, urutan:number, status:string, headline:string, filename:string) => {
	try {
		const response = await axios.put(
          baseURL + "/" + id,
		  {
			"title":title,
			"short_content":short_content,
			"full_content":full_content,
			"filename":filename,
			"author":"author",
			"publication_date":date,
			"youtube":youtube,
			"photo":logo,
			"sort":urutan,
			"headline":headline,
			"status":status,

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}

export const remove = async (id:number) => {
	try {
		const response = await axios.delete(
          baseURL + "/" + id ,
		  {
			

		  }
        );
		
        return response.data;
    } catch (error: any) {
        throw getError(error);
    }

}