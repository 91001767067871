<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Blast Email</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                        >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Blast Email
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">

        <div class="card">
            <div class="card-header p-2">
                <h5>Blast Email</h5>
            </div>
            <!-- /.card-header -->
            <div class="card-body">


                <form class="form-horizontal">
                    <div class="form-group row">
                        <label
                                for="inputName"
                                class="col-sm-2 col-form-label"
                        >Subject Email </label
                        >
                        <div class="col-sm-10">
                            <input
                                    v-model="name"
                                    type="text"
                                    class="form-control"
                                    id="inputName"
                                    placeholder=""
                            />
                        </div>
                    </div>
                   <div class="form-group row">
                        <label
                                for="inputEmail"
                                class="col-sm-2 col-form-label"
                        >Isi Email</label
                        >
                        <div class="col-sm-10">
                            <QuillEditor toolbar="full" theme="snow" v-model:content="full_content" contentType="html"/>

                        </div>
                    </div>
                    <p>&nbsp;</p>


                    <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                            <button
                                    type="button"
                                    @click="submit"
                                    class="btn btn-primary"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>

            </div>
            <!-- /.card-body -->
        </div>
        <!-- /.card -->
    </div>
    <!-- /.col -->

    <!-- /.container-fluid -->
</section>
