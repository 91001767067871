import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/user';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-user',
    components: {
        QuillEditor
    },
	data() {
      return {
        title: "List User",
		mode:1
      };
    },
})
export default class User extends Vue {
	public email: string = '';
	public password: string = '';
	public full_name: string = '';
	public phone: string = '';
	public role: string = '';
	public status:string = '';
	private toast = useToast();
	
	public async submit(): Promise<void>{
        try {
			if(this.email == "")
			{
				this.toast.error('Email harus diisi');
				return;
			}
			
			if(this.password == "")
			{
				this.toast.error('Password harus diisi');
				return;
			}
			
			if(this.phone == "")
			{
				this.toast.error('Telepon harus diisi');
				return;
			}
			
			if(this.role == "")
			{
				this.toast.error('Role User harus diisi');
				return;
			}
			
			
            const response = await create(this.email, this.full_name, this.phone, this.role, this.password, this.status);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('User berhasil disimpan');
				this.$router.push("/user");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
