<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Newsletter</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        Newsletter
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
      
                <div class="card">
                    <div class="card-header p-2">
                       <h5>{{ this.title }}</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
						<router-link class="btn btn-primary" to="/create-newsletter">
							Tambah Newsletter
						</router-link>
						<br/><br/>
						<div class="table-responsive">
							<table class="table table-bordered table-striped table-hover">
								<thead>
									<tr>
									<th>No</th>
										<th>Title</th>
										
										<th>Foto</tH>
										<th>Status</th>
										<th>Urutan</th>
										<th>Aksi</th>
									</tr>
								</thead>
								<tbody>
								<template v-for="(el, index) in records" :key="index">
									<tr>
										<td>{{index + 1}}</td>
										<td>{{el.title}}</td>
										
										<td><img style='width:150px;height:150px;' v-bind:src="imageURL + '/' + el.photo"/></td>
										<td>{{el.status == '1' ? 'Aktif' : 'Tidak Aktif'}}</td>
										<td>{{el.sort}}</td>
											<td>
											<router-link class="btn btn-warning" :to="{path: '/view-newsletter/' + el.id}">
							<span class="fa fa-eye"></span>
						</router-link> &nbsp;
											<router-link class="btn btn-success" :to="{path: '/update-newsletter/' + el.id}">
							<span class="fa fa-edit"></span>
						</router-link> &nbsp;
											<button class="btn btn-danger" v-on:click="remove(el.id)"><span class="fa fa-trash"></span></button>
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
           
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
