<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>FAQ</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >Dashboard</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        FAQ
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
                <div class="card">
                    <div class="card-header p-2">
                       <h5>{{ this.title }}</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
						<router-link class="btn btn-primary" to="/create-faq">
							Tambah FAQ
						</router-link> 
						<br/><br/>
						<input type="hidden" value="{{this.records.length}}"/>
						<div class="table-responsive">
							<table class="table table-bordered table-striped table-hover">
								<thead>
									<tr>
										<th>No</th>
										<th>Pertanyaan</th>
										<th>Jawaban</th>
										<th>Aksi</th>
									</tr>
								</thead>
								<tbody>
								<template v-for="(el, index) in records" :key="index">
									<tr>
										<td>{{index + 1}}</td>
										<td v-html="el.question"></td>
										<td v-html="el.answer"></td>
										<td>
										<router-link class="btn btn-success" :to="{path: '/update-faq/' + el.id}">
							<span class="fa fa-edit"></span>
						</router-link> &nbsp;
											<button class="btn btn-danger" v-on:click="remove(el.id)"><span class="fa fa-trash"></span></button>
										</td>
									</tr>
								</template>
								
								</tbody>
							</table>
						</div>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
           
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>
