import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {update, find} from '@/services/newsletter';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill'

@Options({
    name: 'app-newsletter',
    components: {
		QuillEditor,
    },
	data() {
      return {
        title: "List Newsletter",
		mode:1
      };
    },
})
export default class Newsletter extends Vue {
	public name: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public content: string = '';
	public filename: string = '';
	public logo:string = '';
	public pdf: string = '';
	private toast = useToast();
	private route = useRoute();

	uploadFile(e: any) {
		const file = e.target.files[0];

		if (!file) {
			console.error("No file selected");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			console.log(reader.result);
			this.logo = reader.result?.toString().split(',')[1];
		};
	}

	uploadPdf(e: any) {
		const file = e.target.files[0];

		if (!file) {
			console.error("No file selected");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			console.log(reader.result);
			this.pdf = reader.result?.toString().split(',')[1];
		};
	}
	
	mounted()
	{
	  this.load();
	};
	
	private async load() : Promise<void> {
		try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Newsletter tidak valid');
				this.$router.push("/");
				return;
			}
			
            const response = await find(id);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.filename = response.data.photo;
				this.pdf = response.data.pdf;
				this.name = response.data.title;
				this.urutan = response.data.sort;
				this.status = response.data.status;
				this.content = response.data.content;
			}	
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
	}
	
	
	public async submit(): Promise<void>{
        try {
			const id = this.route.params.id + "";
			if(id == "")
			{
				this.toast.error('Newsletter tidak valid');
				this.$router.push("/");
				return;
			}
			if(this.name == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.content == "")
			{
				this.toast.error('Isi harus diisi');
				return;
			}
           
            const response = await update(id, this.name,  this.logo, this.pdf, this.content, this.urutan,  this.status, this.filename);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Newsletter berhasil diubah');
				this.name = "";
				this.logo = "";
				this.pdf = "";
				this.content = "";
				this.urutan = 1;
				this.$router.push("/newsletter");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
