import {PfImage} from '@profabric/vue-components';
import {Options, Vue} from 'vue-class-component';
import Input from '@/components/input/input.vue';
import {useToast} from 'vue-toastification';
import {create} from '@/services/newsletter';
import {environment} from '../../../../environments/environment';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

@Options({
    name: 'app-newsletter',
    components: {
		QuillEditor,
    },
	data() {
      return {
        title: "List Newsletter",
		mode:1,
		imageURL:environment.imageURL
      };
    },
})
export default class Newsletter extends Vue {
	public name: string = '';
	public status: string = '1';
	public urutan: number = 1;
	public content: string = '';
	public logo: string = '';
	public pdf: string = '';
	private toast = useToast();

	uploadFile(e: any) {
		const file = e.target.files[0];

		if (!file) {
			console.error("No file selected");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			console.log(reader.result);
			this.logo = reader.result?.toString().split(',')[1];
		};
	}

	uploadPdf(e: any) {
		const file = e.target.files[0];

		if (!file) {
			console.error("No file selected");
			return;
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => {
			console.log(reader.result);
			this.pdf = reader.result?.toString().split(',')[1];
		};
	}


	public async submit(): Promise<void>{
        try {
		
			if(this.name == "")
			{
				this.toast.error('Judul harus diisi');
				return;
			}
			
			if(this.content == "")
			{
				this.toast.error('Isi harus diisi');
				return;
			}
			
			if(this.logo == "")
			{
				this.toast.error('Foto harus diisi');
				return;
			}
			if(this.pdf == "")
			{
				this.toast.error('Pdf harus diisi');
				return;
			}
            const response = await create(this.name, this.logo, this.pdf, this.content, this.urutan,  this.status);
			if(response.status == false)
			{
				this.toast.error(response.message);
			}
			else
			{
				this.toast.success('Newsletter berhasil disimpan');
				this.name = "";
				this.logo = "";
				this.pdf = "";
				this.content = "";
				this.$router.push("/newsletter");
			}
			
        } catch (error: any) {
            this.toast.error(error.message);
           
        }
    }
}
